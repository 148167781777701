    // src/App.js
    import React, { useState } from 'react';
    import { useNavigate } from 'react-router-dom';

    import Header from './Header';
    import Marquee from './Marquee';
    import Footer from './Footer';
    import Card from './Card';

    import Sidebar from './Sidebar';
    import './HomePage.css';
      import './App.css';

    const App = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
    const closeSidebar = () => setIsSidebarOpen(false);
  const handleClick = () => {
         window.location.href = 'https://hsrpplate-book.in/book/form-personal.html';
    };
    return (
    <div className="App">
    <Header toggleSidebar={toggleSidebar} />
    <Sidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
    <main>
   <h2>Welcome to HSRP BOOK </h2>
    <div className="card-container">
    <Card
    title="High Security Registration Plate with Colour Sticker 𖥞"
    backgroundColor="#fde58a"
    buttonText="Book Here Now"
    textColor="#00000"
    onButtonClick={handleClick}
    />


      <Card
    title="High Security Registration Plate For EV 𖥞"
    backgroundColor="#324b96"
    buttonText="Book Here Now"
    textColor="#ffffff"
    onButtonClick={handleClick}
    />


      <Card
    title="Only Colour Sticker Book here"
    backgroundColor="#fde58a"
    buttonText="Book Here Now"
    textColor="#000"
    onButtonClick={handleClick}
    />


      <Card
    title="High Security Registration Plate For Tractor 𖥞"
    backgroundColor="#324b96"
    buttonText="Book Here Now"
    textColor="#fff"
    onButtonClick={handleClick}
    />


      <Card
    title="Replacement / Retain / Transfer"
    backgroundColor="#fde58a"
    buttonText="Book Here Now"
    textColor="#000"
    onButtonClick={handleClick}
    />


      <Card
    title="High Security Registration Plate For 2 / 3 / 4 𖥞"
    backgroundColor="#324b96"
    buttonText="Book Here Now"
    textColor="#fff"
    onButtonClick={handleClick}
    />
    </div>
    <Marquee 
    text="Booking Available for state of Karnataka, Madhya Pradesh, Andhra Pradesh, Assam, Bihar, Gujarat, Himachal Pradesh, Odisha, Uttar Pradesh, West Bengal and NCT & Delhi." 
    backgroundColor="transparent" 
    speed={25}
    textColor="#da0606" 
    />
    <Footer />
    </main>
    </div>
    );
    };

    export default App;
