    import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom'; // For navigation
import Header from './Header';
 import Footer from './Footer';
 import Marquee from './Marquee';
     import Sidebar from './Sidebar';
    import './HomePage.css';
      import './App.css';
import './FormPage.css'; // Import your CSS file

// Validation Schema with Yup
const schema = yup.object().shape({
    name: yup.string().required('Owner Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
     mobile: yup.string().min(10, 'Mobile No must be 10 characters').required('Mobile No is required'),
    vehicle: yup.string().min(10, 'Vehicle Reg No must be 10 characters').required('Vehicle Reg No is required'),
    plate: yup.string().required('No of Plate is required'),
    vtype: yup.string().required('Vehicle Type is required'),
    state: yup.string().required('State is required'),
address: yup.string().required('Shipping address is required'),
});

const FormPage = () => {
       const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
    const closeSidebar = () => setIsSidebarOpen(false);
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const navigate = useNavigate(); // Hook for navigation

    const onSubmit = async (data) => {
        try {
            const response = await fetch('https://hsrpplate-book.in/submit.php', { // Replace with your live server URL
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();
            if (result.status === 'success') {
                // Redirect to the next page
                      sessionStorage.setItem('formData', JSON.stringify(data));

                navigate('/success');
            } else {
                alert(result.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('There was an error submitting the form.');
        }
    };

    return (
        <div className="App">
    <Header toggleSidebar={toggleSidebar} />
    <Sidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      <Marquee 
    text="Booking Available for state of Karnataka, Madhya Pradesh, Andhra Pradesh, Assam, Bihar, Gujarat, Himachal Pradesh, Odisha, Uttar Pradesh, West Bengal and NCT & Delhi." 
    backgroundColor="transparent" 
    speed={25}
    textColor="#da0606" 
    />
    <h2 className="myw">Welcome to HSRP BOOK </h2>
        <div className="form-container">
            <h2>Vehicle Details / वाहन विवरण</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
             <div className="form-group">
                    <label htmlFor="password">Vehicle Registration Number / वाहन पंजीकरण संख्या*</label>
                    <input
                        id="vehicle"
                        type="text"
                        {...register('vehicle')}
                        className={`form-control ${errors.vehicle ? 'error' : ''}`}
                    />
                    {errors.vehicle && <p className="error-message">{errors.vehicle.message}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="name">Owner Name / मालिक का नाम*</label>
                    <input
                        id="name"
                        type="text"
                        {...register('name')}
                        className={`form-control ${errors.name ? 'error' : ''}`}
                    />
                    {errors.name && <p className="error-message">{errors.name.message}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email Id*</label>
                    <input
                        id="email"
                        type="email"
                        {...register('email')}
                        className={`form-control ${errors.email ? 'error' : ''}`}
                    />
                    {errors.email && <p className="error-message">{errors.email.message}</p>}
                </div>

                   <div className="form-group">
                    <label htmlFor="password">Mobile No*</label>
                    <input
                        id="mobile"
                        type="text"
                        {...register('mobile')}
                        className={`form-control ${errors.mobile ? 'error' : ''}`}
                    />
                    {errors.mobile && <p className="error-message">{errors.mobile.message}</p>}
                </div>
               
                <div className="form-group">
                    <label htmlFor="plate">Number of Plates / प्लेट की संख्या*</label>
                    <select
                        id="plate"
                        {...register('plate')}
                        className={`form-control ${errors.plate ? 'error' : ''}`}
                    >
                        <option value="1">1 Plate</option>
                        <option value="2">2 Plate</option>
                    </select>
                    {errors.plate && <p className="error-message">{errors.plate.message}</p>}
                </div>


                 <div className="form-group">
                    <label htmlFor="plate">Vehicle Type / वाहन का प्रकार*</label>
                    <select
                        id="vtype"
                        {...register('vtype')}
                        className={`form-control ${errors.vtype ? 'error' : ''}`}
                    >
                        <option value="1">Color Sticker only</option>
                        <option value="2">Two Wheeler</option>
                        <option value="3">Three Wheeler</option>
                        <option value="4">Four Wheeler</option>
                        <option value="5">Heavy Vehicle</option>
                        <option value="6">Electric Vehicle</option>
                    </select>
                    {errors.vtype && <p className="error-message">{errors.vtype.message}</p>}
                </div>

                <div className="form-group">
<label htmlFor="password">Shipping Address*</label>
<textarea
id="address"
type="text"
{...register('address')}
className={`form-control ${errors.address ? 'error' : ''}`}
/>
{errors.address && <p className="error-message">{errors.address.message}</p>}
</div>



<div className="form-group">
<label htmlFor="plate">State /राज्य *</label>
<select
id="state"
{...register('state')}
className={`form-control ${errors.vtype ? 'error' : ''}`}
>
<option value="">--Select State--</option>
<option value="Andhra Pradesh">Andhra Pradesh</option>
<option value="Andaman and Nicobar Islands">
Andaman and Nicobar Islands
</option>
<option value="Arunachal Pradesh">Arunachal Pradesh</option>
<option value="Assam">Assam</option>
<option value="Bihar">Bihar</option>
<option value="Chandigarh">Chandigarh</option>
<option value="Chhattisgarh">Chhattisgarh</option>
<option value="Dadar and Nagar Haveli">
Dadar and Nagar Haveli
</option>
<option value="Daman and Diu">Daman and Diu</option>
<option value="Delhi">Delhi</option>
<option value="Lakshadweep">Lakshadweep</option>
<option value="Puducherry">Puducherry</option>
<option value="Goa">Goa</option>
<option value="Gujarat">Gujarat</option>
<option value="Haryana">Haryana</option>
<option value="Himachal Pradesh">Himachal Pradesh</option>
<option value="Jammu and Kashmir">Jammu and Kashmir</option>
<option value="Jharkhand">Jharkhand</option>
<option value="Karnataka">Karnataka</option>
<option value="Kerala">Kerala</option>
<option value="Madhya Pradesh">Madhya Pradesh</option>
<option value="Maharashtra">Maharashtra</option>
<option value="Manipur">Manipur</option>
<option value="Meghalaya">Meghalaya</option>
<option value="Mizoram">Mizoram</option>
<option value="Nagaland">Nagaland</option>
<option value="Odisha">Odisha</option>
<option value="Punjab">Punjab</option>
<option value="Rajasthan">Rajasthan</option>
<option value="Sikkim">Sikkim</option>
<option value="Tamil Nadu">Tamil Nadu</option>
<option value="Telangana">Telangana</option>
<option value="Tripura">Tripura</option>
<option value="Uttar Pradesh">Uttar Pradesh</option>
<option value="Uttarakhand">Uttarakhand</option>
<option value="West Bengal">West Bengal</option>
</select>
{errors.state && <p className="error-message">{errors.state.message}</p>}
</div>
                <button type="submit" className="submit-button">Next: Shipping Details / अगला: शिपिंग विवरण</button>
            </form>
        </div>
          <Marquee 
    text="Booking Available for state of Karnataka, Madhya Pradesh, Andhra Pradesh, Assam, Bihar, Gujarat, Himachal Pradesh, Odisha, Uttar Pradesh, West Bengal and NCT & Delhi." 
    backgroundColor="transparent" 
    speed={25}
    textColor="#da0606" 
    />
         <Footer />
   
    </div>
    );
};

export default FormPage;
