// src/Card.js
import React from 'react';
import './Card.css';

const Card = ({ backgroundColor, textColor, icon, title, description, buttonText, onButtonClick }) => {
  return (
    <div className="card" style={{ backgroundColor }}>
      <div className="card-icon">{icon}</div>
            <h3 className="card-title" style={{ color: textColor }}>{title}</h3>
      <p className="card-description">{description}</p>
      <button className="card-button" style={{ color: textColor   }} onClick={onButtonClick}>
        {buttonText}
      </button>
    </div>
  );
};

export default Card;
