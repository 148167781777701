import React, { useEffect, useState } from 'react';
    import { useNavigate } from 'react-router-dom';

    import Header from './Header';
    import Marquee from './Marquee';
    import Footer from './Footer';
    import Card from './Card';

    import Sidebar from './Sidebar';
    import './HomePage.css';
      import './App.css';

    const App = () => {
    	  const [formData, setFormData] = useState(null);

    useEffect(() => {
        // Retrieve form data from session storage
        const data = sessionStorage.getItem('formData');
        if (data) {
            setFormData(JSON.parse(data));
            // Optionally, clear the session data after retrieving it
            sessionStorage.removeItem('formData');
        }
    }, []);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
    const closeSidebar = () => setIsSidebarOpen(false);
  const handleClick = () => {
        navigate('/book-hsrp-plate'); // Navigate to the NextPage component
    };
    return (
    <div className="App">
    <Header toggleSidebar={toggleSidebar} />
    <Sidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
    <main>
   <h2>Welcome to HSRP BOOK </h2>
    <div className="next-page-container">
            <h1>Thank You!</h1>
            {formData ? (
                <div>
                    <p><strong>Name:</strong> {formData.name}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                    <p><strong>Gender:</strong> {formData.gender}</p>
                </div>
            ) : (
                <p>No data available.</p>
            )}
        </div>
    <Footer />
    </main>
    </div>
    );
    };

    export default App;
